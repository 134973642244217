import "../../../typings/common";
import "../../../typings/client";
import $ from "jquery";
import "external-svg-loader";
import version from "../../../common/version";

$("[data-version").text(version);

import "./bg_image_crop";

export * as Notifications from "./notifications";
export * as navigation from "./navigation";


import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fab);
dom.watch();
